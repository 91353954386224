<template>
  <form
    class="user-verification-form"
    :class="{ mobile: isMobile }"
    data-t="user-verification-form"
    @submit.prevent
  >
    <h2 class="section-title">
      {{ t('userVerificationModal.personalData') }}
    </h2>

    <div class="fields">
      <StInput
        v-bind="name.componentBindings"
        :placeholder="t('userVerificationModal.namePlaceholder')"
        data-t="full-name"
        autocomplete="given-name"
        :size="inputSize"
        autofocus
      />
      <StInput
        v-bind="birthDate.componentBindings"
        :placeholder="
          t('userVerificationModal.birthDatePlaceholder', {
            format: dateFormat,
          })
        "
        data-t="birth-date"
        :mask="dateMask"
        autocomplete="bday"
        inputmode="numeric"
        :size="inputSize"
      />
    </div>

    <h2 class="section-title">{{ t('userVerificationModal.address') }}</h2>
    <div class="fields">
      <StSearch
        v-bind="country.componentBindings"
        :options="countrySelectOptions"
        :placeholder="t('userVerificationModal.countryPlaceholder')"
        :search-placeholder="t('userVerificationModal.inputSearch')"
        :drawer-title="t('userVerificationModal.countryPlaceholder')"
        data-t="country"
      />
      <StInput
        v-bind="town.componentBindings"
        :placeholder="t('userVerificationModal.cityPlaceholder')"
        data-t="town"
        autocomplete="address-level2"
        :size="inputSize"
      />
      <StInput
        v-bind="address.componentBindings"
        :placeholder="t('userVerificationModal.addressPlaceholder')"
        data-t="address"
        autocomplete="street-address"
        :size="inputSize"
      />
    </div>
    <div class="footer">
      <p class="security">
        <StIcon name="shield-check" size="16" />
        {{ t('userVerificationModal.confidentialInfo') }}
      </p>

      <StButton
        class="save-button"
        :size="isMobile ? 'l' : 'xl'"
        :loading="isLoading"
        data-t="save-button-qul1"
        submit
        @click="handleSubmit"
      >
        {{ t('userVerificationModal.saveAndContinueButton') }}
      </StButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import { useForm, required, lengthBetween, atLeastTwoWords } from '@st/validate'
import { useVerificationDeps } from '../../useDeps'
import { useVerificationCountryList } from './useVerificationCountryList'
import { useDateInput } from './useDateInput'

const { isMobile } = usePlatform()
const { t } = useI18n()
const rawStFetch = useRawStFetch()
const inputSize = computed(() => (isMobile.value ? 'm' : 'l'))
const { setKycLevel } = useVerificationDeps()

const { initialValues = {} } = defineProps<{
  initialValues?: {
    firstName?: string
    lastName?: string
    birthDate?: string
    country?: string
    town?: string
    address?: string
    postCode?: string | null
  }
}>()

const emit = defineEmits<{
  success: []
}>()

const countrySelectOptions = useVerificationCountryList()

const { parseDate } = useDate()
const { dateMask, dateFormat, parseDateInput } = useDateInput()
const BACKEND_DATE_FORMAT = 'YYYY-MM-DD'

const formattedInitialValues = computed(() => ({
  fullName:
    initialValues.firstName && initialValues.lastName
      ? `${initialValues.firstName} ${initialValues.lastName}`
      : '',
  birthDate: initialValues.birthDate
    ? parseDate(initialValues.birthDate, BACKEND_DATE_FORMAT).format(
        dateFormat.value,
      )
    : '',
  country: initialValues.country?.toLowerCase()?.slice(0, 3) ?? '',
  town: initialValues.town ?? '',
  address: initialValues.address ?? '',
}))

const {
  fields: { name, birthDate, country, town, address },
  isValid,
  values,
  validate,
} = useForm({
  fieldsSchema: {
    name: {
      initialValue: formattedInitialValues.value.fullName,
      validators: [
        {
          rule: required,
          errorMessage: () => t('userVerificationModal.errors.required'),
        },
        {
          rule: lengthBetween(2, 50),
          errorMessage: () =>
            t('userVerificationModal.errors.lengthBetween', {
              min: 2,
              max: 50,
            }),
        },
        {
          rule: (value) => /^[A-Za-z\s'-]{2,50}$/.test(value),
          errorMessage: () => t('userVerificationModal.errors.firstName'),
        },
        {
          rule: atLeastTwoWords,
          errorMessage: () =>
            t('userVerificationModal.errors.requiredFullname'),
        },
      ],
    },
    birthDate: {
      initialValue: formattedInitialValues.value.birthDate,
      validators: [
        {
          rule: required,
          errorMessage: () => t('userVerificationModal.errors.required'),
        },
        {
          /**
           * последний параметр (true) - для строгого режима парсинга
           * иначе считаются валидными кривые даты типа 40/40/1999
           */
          rule: (value) => parseDateInput(value).isValid(),
          errorMessage: () =>
            t('userVerificationModal.errors.birthDateInvalid'),
        },
        {
          rule: (value) => {
            const birthday = parseDateInput(value)
            const now = parseDate()

            return now.diff(birthday, 'years') >= 18
          },
          errorMessage: () => t('userVerificationModal.errors.ageRestriction'),
        },
      ],
    },
    country: {
      initialValue: formattedInitialValues.value.country,
      validators: [
        {
          rule: required,
          errorMessage: () => t('userVerificationModal.errors.required'),
        },
      ],
    },
    town: {
      initialValue: formattedInitialValues.value.town,
      validators: [
        {
          rule: required,
          errorMessage: () => t('userVerificationModal.errors.required'),
        },
        {
          rule: lengthBetween(2, 100),
          errorMessage: () =>
            t('userVerificationModal.errors.lengthBetween', {
              min: 2,
              max: 100,
            }),
        },
        {
          rule: (value) => /^[A-Za-z\s-()]{2,100}$/.test(value),
          errorMessage: () => t('userVerificationModal.errors.city'),
        },
      ],
    },
    address: {
      initialValue: formattedInitialValues.value.address,
      validators: [
        {
          rule: required,
          errorMessage: () => t('userVerificationModal.errors.required'),
        },
        {
          rule: lengthBetween(5, 200),
          errorMessage: () =>
            t('userVerificationModal.errors.lengthBetween', {
              min: 5,
              max: 200,
            }),
        },
        {
          rule: (value) => /^[A-Za-z0-9\s,.-]{5,200}$/.test(value),
          errorMessage: () => t('userVerificationModal.errors.address'),
        },
      ],
    },
  },
})

const toast = useToast()

const requestBody = computed(() => {
  const { name: rawFullname, ...restParams } = values.value
  const fullname = rawFullname.trim()
  const spaceSymbolIndex = fullname.trim().indexOf(' ')
  return {
    ...restParams,
    firstName: fullname.slice(0, spaceSymbolIndex),
    lastName: fullname.slice(spaceSymbolIndex + 1),
    country: values.value.country.toUpperCase(),
    birthDate: parseDateInput(values.value.birthDate).format(
      BACKEND_DATE_FORMAT,
    ),
  }
})

const isLoading = ref(false)
async function handleSubmit() {
  validate()

  if (!isValid.value) {
    return
  }

  isLoading.value = true
  const { error } = await rawStFetch('/user-verification/unverified-data/set', {
    method: 'post',
    body: requestBody.value,
  })
  isLoading.value = false

  if (error) {
    toast.open({
      type: 'negative',
      label: t('userVerificationModal.errors.somethingWentWrong'),
    })
    return
  }
  setKycLevel(0)
  emit('success')
}
</script>

<style scoped>
h2,
h3,
p {
  margin: 0;
}

.user-verification-form {
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.finish-registration {
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  padding-top: var(--spacing-300);
}

.fields {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  margin-bottom: var(--spacing-300);
}

.section-title {
  margin-bottom: var(--spacing-125);
  font: var(--desktop-text-lg-semibold);
}

.security {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  justify-content: center;

  margin-top: var(--spacing-100);

  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.save-button {
  width: 100%;
  margin: var(--spacing-200) 0 0;
}

.mobile {
  &.user-verification-form {
    flex-grow: 1;
    width: auto;

    .section-title {
      font: var(--mobile-text-semibold);
    }

    .security {
      margin-top: 0;
      font: var(--mobile-caption-1-medium);
    }
  }

  .footer {
    margin-top: auto;
  }
}
</style>
